import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgProfileKevinMortlock from "../images/profile-kevin-mortlock.jpg"
import imgCaseStudy from "../images/keane-travel-case-study.jpg"
import "./index.css";
import "./case-study.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Case study: How Keane Travel halved admin and increased revenue 50% on their school services" />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <div className="case-study">

                <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                  <h1 className="blog-headline">How Keane Travel halved admin and increased revenue on their school services</h1>
                </div>

                <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                  <h1 className="blog-headline">How Keane Travel halved admin and increased revenue on their school services</h1>
                </div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>It’s a tale many transport providers will identify with. Keane Travel struggled with the admin weighing down its home-to-school services. The hassle of operating a manual ticket system – the back-and-forth emails with parents, chasing payments, and printing and posting passes – meant that expanding its school routes further was just a pipedream.</p>
                    <p>Today, with ShuttleID, Keane Travel has a first-class system underpinning its leading transport services. Selling paperless tickets online and taking payments by direct debit are just two ways the firm has reduced its overall admin by a whopping 50%. For Keane Travel’s managing director Kevin Mortlock, ShuttleID really has been game changing.</p>
                  </div>
                  <div className='article-inner'>
                    <div className='case-study__metrics'>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>50%</span>
                        admin saved
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>90%</span>
                        less failed payments
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>50%</span>
                        revenue increased
                      </div>
                    </div>
                    <div className='case-study__about'>
                      <h3>About Keane Travel Ltd</h3>
                      <p><a href="https://keanetravel.co.uk/">Keane Travel</a> is a family run business that has operated bus and coach services for many years. Based in Stanford-le-Hope, it is one of the leading providers in Essex offering private, corporate and school hire services.</p>
                    </div>

                    <h2>Admin aggro</h2>
                    <p>When Kevin Mortlock, Director of Keane Travel Ltd, acquired his bus routes he soon realised they lacked any kind of robust system to manage the home-to-school services. His first thought was to create a monthly ticket for his drivers to hand out to children onboard. As most parents will testify, many tickets often ended up lost or going through the washing machine, and Kevin seemed to be forever answering requests to dish out replacements.</p>
                    <p>Next, in a bid to secure regular payments, Kevin asked parents to pay for tickets by standing order on the 28th of each month. Sounds simple, but he’d spend his days and nights checking if all the money had actually landed in the account – a very laborious and downright dull task.</p>
                    <p>Once he’d received payment, Kevin would sit and write out the tickets and pass them to the drivers to give to children on the bus at the start of each month. In theory it was straightforward, but the bus would run late as the driver matched the right child to the right ticket. With so many variables, it’s no wonder things were hard work.</p>
                    <div className='case-study__quote'>
                      <p>"It was quite a nightmare, really."</p>
                      <p className='case-study__quote-author'>&mdash; Kevin Mortlock, Managing Director, Keane Travel Ltd</p>
                    </div>


                    <h2>Chasing payments</h2>
                    <p>As passenger numbers climbed, Kevin realised his paper ticket wasn’t going to cut it. Instead, he invested in a machine that would produce plastic cards with an electronic chip and reader. Frustratingly, this didn’t resolve his problems either: Kevin still had to add credit to each card one by one, taking up even more of his precious time at the end of each month. Plus, with cards being misplaced, lost (or eaten by the dog), the idea of a child keeping the same card for five years quickly went out of the window.</p>
                    <p>With no sign of demand slowing, Kevin knew it would soon be impossible to manually check over 300 individual payments.. Each month, whilst he’d relish the thought of money coming in, he would dread the admin that he knew would follow. An average payment-failure rate of 20% meant the following days would see him making tedious calls to chase funds and reset standing orders, all on top of dealing with any regular calls, texts and emails.</p>
                    <p>Clearly exasperated, Kevin couldn’t realise the potential in his business. He could foresee the notion of opening a new route was a nonstarter; he simply wouldn’t have the time to create and maintain another 80 handwritten tickets or plastic cards. He needed a system that would allow him to manage his existing routes and open new ones – a tech-based platform that could not only keep up with but, in fact drive, his vision.</p>
                    <p><img src={imgCaseStudy} alt="Applegates Coaches" /></p>

                    <h2>Shifting from manual to automatic</h2>
                    <p>Kevin discovered ShuttleID and saw the immediate advantage of doing away with manual tickets. He found the online portal easy to get to grips with and before long he got his services loaded and tickets up and running. Much to Kevin’s relief, ShuttleID also let him quickly add a new route, minus any of the headaches he’d predicted.</p>
                    <p>After listing his services, tickets and all-important payment terms, Kevin advertised the routes on the Keane Travel website, along with a “buy ticket” button. His customers simply selected their route and clicked for ticket availability. What once took a string of time-sapping emails or phone calls was done in a couple of clicks. For those who preferred paying monthly, the answer was a straightforward direct debit ticket. It proved super-easy: once the mandate was set up and verified by the system, the customer’s ticket was winging its way through the ether – all pretty intuitive, instantaneous and, most importantly, with no involvement from Kevin.</p>

                    <h2>Taking the back seat</h2>
                    <p>As well as saving time and stress, Kevin now feels like he’s able to step back from the firefighting and look at the bigger picture of his business. Meanwhile ticket sales merrily chug along in the background.</p>

                    <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileKevinMortlock} alt="Ceri Taylor" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p>"Recently, I was on holiday and, as usual, I took my laptop with me. Every other day I went on my ShuttleID portal to see how many tickets I’d sold and how close I was to my new targets. Compare that with two years ago? What a difference! With the customer numbers we’re doing now, the old system would have been Bedlam. This holiday I didn’t have to contact any parents or help set up standing orders. All I had to do was watch the numbers creep up. I’m now exceeding my targets, so it has definitely been a game changer."</p>
                        <p className='case-study__profile-quote__author'>Kevin Mortlock, Managing Director, Keane Travel Ltd</p>
                      </div>
                    </div>

                    <h2>A business that’s going places</h2>
                    <p>Kevin believes he has picked up extra passengers because of the simplicity and speed of buying a ticket online. Before, parents would receive a flyer and would have to navigate the lengthy process of emailing and setting up standing orders. Now, with ShuttleID, parents just click the link, buy the ticket and they’ve got it in a flash on their phone, without Kevin having to print and post anything.</p>
                    <p>It even works out of office hours. Kevin had one customer on Facebook ask him about a ticket. He was able to forward her a ticket link and soon it was bought, paid for and waiting on the child’s phone.</p>
                    <p>"A couple of clicks and they've got the paperless ticket, there in their pocket. There’s no doubt I've gained new passengers because everyone finds the system so easy to use."</p>

                    <h2>Information station</h2>
                    <p>The e-tickets are shared easily by parents with their tech-savvy school children, ready to be scanned onboard the buses. The scanner notifies drivers of any ticket issues and Kevin can review the scan log for all the buses, any time he wants, from within the system.</p>
                    <p>The scan logs have already proved invaluable. During the pandemic, for example, Kevin was asked by a school if a particular child with Covid had travelled on his bus. Kevin was able to access the scan reports and pinpoint exactly when the child had boarded, which only served to strengthen his school partnership.</p>

                    <p>"ShuttleID works absolutely perfectly. It's seamless. The drivers like it. The kids like it. In a nutshell, in my view it is fantastic!"</p>
                    <h2>A win win system</h2>
                    <p>Whilst school bus tickets have become a breeze for parents and carers to buy, Kevin now has full visibility and control of payments. He no longer has the worry of working out who has or hasn’t paid. Plus, should any direct debit payment fail, Kevin’s notification email means he can retry collecting payment with one click of a button. What’s great too is that the buyer receives a reminder to add sufficient funds for the payment to go through.</p>
                    <p>"I don't have to check each passenger individually anymore; I just get to hear about the ones who haven't paid. I was initially after an easier way to get tickets to the passengers. I didn't think for one minute that ShuttleID would solve my bad debtors."</p>
                    <p>Overall, Kevin believes ShuttleID has reduced his admin by at least 50%. When asked if he would ever return to his previous system he replied, “<strong>The old system was a different league. We're in the Premiership now.</strong>”</p>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
